<template>
  <div class=" container-fluid container-fixed-lg bg-white app-table-container">
    <ConfirmModal ref="modal_delete_entry" :modal-header-title="$t('common.confirm_delete_product_draft')"
                  :confirm-handle="deleteEntrySubmit">
    </ConfirmModal>

    <ConfirmModal ref="modal_delete_public_entry" :modal-header-title="$t('common.confirm_delete_product_public')"
                  :confirm-handle="deleteEntrySubmit">
    </ConfirmModal>

    <ConfirmModal
      class="modal-product"
      ref="modal_confirm_publish_all"
      :modal-header-title="$t('common.confirm_publish_all_product')"
      :confirm-handle="publishAllProduct">
    </ConfirmModal>

    <ConfirmModal
      ref="modal_confirm_draft_all"
      :modal-header-title="$t('common.confirm_draft_all_product')"
      :confirm-handle="draftAllProduct">
    </ConfirmModal>

    <ConfirmModal
      ref="modal_confirm_delete_all"
      :modal-header-title="$t('common.confirm_delete_product_public')"
      :confirm-handle="deleteAllProduct">
    </ConfirmModal>
    <div class="card card-transparent">
      <div class="card-header search-container" v-if="search.searchAll || hasFiltersUi">
        <div class="search-content">
          <ValidationObserver v-slot="{ passes, valid, validated }">
            <label class="control-label input-label text-complete pb-3 ml-2">{{$t("nav.furusato_product_list")}}</label>
            <form novalidate autocomplete="off" @submit.prevent="passes(searchHandle)">
              <div v-if="search.searchAll && filters.length === 0 ||
              !search.searchAll && filters.length === 1" class="row">
                <div class="d-flex justify-content-start col-md-12">
                  <div class="w-25">
                    <AppInput input-style="normal" v-if="search.searchAll" v-model="pagination.search"/>
                    <template v-for="(filter, index) in filters">
                      <template v-if="filter.uiType === 'input'">
                        <AppInput :name="filter.label" :label="filter.label" input-style="normal"
                                  v-model="filters[index].value"/>
                      </template>
                      <template v-else-if="filter.uiType === 'select'">
                        <AppSelect
                          :options="{containerCssClass: 'z-index-0', placeholder: filter.placeholder}" input-style="normal" :name="filter.name"
                          :label="filter.label"
                          :options-data="filter.options"
                          v-model="filters[index].value"
                          :onChange="(id) => changePrefecture(id, filter.name)"
                        />
                      </template>
                      <template v-else-if="filter.uiType === 'datepicker'">
                        <AppDatePicker input-style="normal" :name="filter.label" :label="filter.label"
                                       :rules="filter.rules ? filter.rules : []"
                                       v-model="filters[index].value"/>
                      </template>
                    </template>
                  </div>
                  <div class="m-t-auto m-b-15">
                    <button :disabled="!valid && validated" class="btn btn-complete m-l-10 m-r-10"
                            type="submit">
                      {{ $t('common.search') }}
                      <i
                        class="fa fa-search"></i>
                    </button>
                    <button v-if="search.reset"
                            @click="((typeof search.reset) === 'function') ? search.reset() : resetAllSearch()"
                            class="btn btn-default m-r-10" type="button">
                      {{ $t('common.reset') }}
                    </button>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="row">
                  <div v-if="search.searchAll" class="col-md-2-4 col-sm-6">
                    <AppInput :label="$t('common.text search')" type="text" v-model="pagination.search"/>
                  </div>
                  <div v-for="(filter, index) in filters"
                       :class="[filter.boundaryClass ? filter.boundaryClass : 'col-md-2-4 col-sm-6']" v-if="filter.uiType">
                    <template v-if="filter.uiType === 'input'">
                      <AppInput :name="filter.name"
                                :label="filter.label"
                                input-style="normal"
                                label-class="text-complete"
                                v-model="filters[index].value"/>
                    </template>
                    <template v-if="filter.uiType === 'input_hidden'">
                      <span class="span-text">~</span>
                    </template>
                    <template v-else-if="filter.uiType === 'select'">
                      <AppSelect
                        :options="{containerCssClass: 'z-index-0', placeholder: filter.placeholder ? filter.placeholder : filter.label}"
                        input-style="normal" :name="filter.name"
                        :label="filter.label"
                        label-class="text-complete"
                        :options-data="filter.options"
                        v-model="filters[index].value"
                        :value="filters[index].value"
                        :onChange="(id) => changePrefecture(id, filter.name)"
                      />
                    </template>
                    <template v-else-if="filter.uiType === 'multi_select'">
                      <AppSelectMulti :options="{...filter.ui_options, containerCssClass: 'z-index-0',placeholder: filter.placeholder ? filter.placeholder : filter.label}"
                                      input-style="normal" :name="filter.name"
                                      :label="filter.label"
                                      label-class="text-complete"
                                      :onChange="filter.changeEvent ? filter.changeEvent : null"
                                      :options-data="filter.options"
                                      v-model="filters[index].value"/>
                    </template>
                    <template v-else-if="filter.uiType === 'datepicker'">
                      <AppDatePicker :rules="filter.rules ? filter.rules : ''"
                                     input-style="normal" :name="filter.name"
                                     :label="filter.label"
                                     label-class="text-complete"
                                     :options="filter.options ? filter.options : {}"
                                     v-model="filters[index].value"/>
                    </template>
                    <template v-else-if="filter.uiType === 'datepicker_from'">
                      <div class="row">
                        <div class="col-md-5-5">
                          <AppDatePicker :rules="filter.rules ? filter.rules : ''"
                                         input-style="normal" :name="filter.name"
                                         :label="filter.label"
                                         label-class="text-complete"
                                         v-model="filters[index].value"/>
                        </div>
                        <div class="col-md-1 p-0 m-0">
                          <div class="form-group app-form-group b-b-0">
                            <label class="input-label control-label p-0 p-r-5 visible-none">
                              none
                            </label>
                            <div class="form-group m-b-0 p-0 text-center" style="height: 50px; line-height: 50px">
                              ～
                            </div>
                          </div>
                        </div>
                        <div class="col-md-5-5">
                          <AppDatePicker
                            :rules="filters[index+1].rules ? filters[index+1].rules : ''"
                            input-style="normal"
                            label="none"
                            :name="filters[index+1].name"
                            label-class=" visible-none"
                            v-model="filters[index+1].value"/>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="m-t-10 row justify-content-center">
                  <button v-if="search.reset"
                          @click="((typeof search.reset) === 'function') ? search.reset() : resetAllSearch()"
                          class="btn btn-default m-r-10" type="button">
                    {{ $t('common.reset') }}
                  </button>
                  <button :disabled="!valid && validated" class="btn btn-complete m-r-10" type="submit">
                    {{ $t('common.search') }}
                  </button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
      <div class="card-header d-flex justify-content-between">
        <div class="card-title table-data-info">
          <div class="dataTables_info app-text" style="text-transform: lowercase" role="status" aria-live="polite">
            <template v-if="$cookies.get($config.APP_NAME + 'lang') == 'en'">
              <span style="text-transform: uppercase">S</span>howing {{ meta.from }} 〜 {{ meta.to }} of {{ meta.total }}
              records
            </template>
            <template v-else>
              全{{ meta.total }}件中 {{ meta.from }}件 〜 {{ meta.to }}件 を表示
            </template>
          </div>
        </div>
          <div class="export-options-container ml-2">
            <div class="exportOptions">
              <div class="btn-group">
                <div>
                  <button class="btn btn-create m-r-10" @click="$router.pushByName($consts.ROUTES.ADMIN.FURUSATO_PRODUCT_CREATE)">
                    {{ $t('common.create') }}
                  </button>
                  <button v-if="entries.length > 0" @click="confirmPublishAll"
                          class="btn btn-complete app-text m-r-10">
                    {{ $t('furusato_product.publish_all') }}
                  </button>
                  <button v-if="entries.length > 0" @click="confirmDraftAll"
                          class="btn btn-complete app-text m-r-10">
                    {{ $t('furusato_product.draft_all') }}
                  </button>
                  <button v-if="entries.length > 0" @click="confirmDeleteAll"
                          class="btn btn-complete app-text m-r-10">
                    {{ $t('furusato_product.delete_all') }}
                  </button>
                  <template v-if="actions.headers && actions.headers.length" v-for="action in actions.headers">
                    <template v-if="action.click">
                      <span v-html="action.contentHtml(entry)" @click="action.click()"></span>
                    </template>
                    <template v-else-if="action.routerLink">
                      <router-link :to="action.routerLink()">
                        <span v-html="action.contentHtml()"></span>
                      </router-link>
                    </template>
                    <template v-else>
                      <span v-html="action.contentHtml()"></span>
                    </template>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="dataTables_wrapper no-footer bg-white">
          <div class="w-100" >
            <div class="col-md-12 p-0" style="margin-top:30px;">
              <ul class="nav nav-tabs nav-tabs-fillup d-none d-md-flex d-lg-flex d-xl-flex furusato-nav"  data-init-reponsive-tabs="dropdownfx">
                <li class="nav-item">
                  <a :class="menuActive == '0' ? 'active show' : activeClass" data-toggle="tab" role="tab" @click="getListProduct()">
                    <span>{{ $t('furusato_product.all_product_tab') }}</span>
                  </a>
                </li>

                <li class="nav-item">
                  <a :class="menuActive == $consts.INPUT.TYPE_PUBLIC ? 'active show' : ''" data-toggle="tab" role="tab" @click="getListProduct($consts.INPUT.TYPE_PUBLIC)">
                    <span>{{ $t('furusato_product.all_public_product_tab') }}</span>
                  </a>
                </li>

                <li class="nav-item">
                  <a :class="menuActive == $consts.INPUT.TYPE_DRAFT ? 'active show' : ''" data-toggle="tab" role="tab" @click="getListProduct($consts.INPUT.TYPE_DRAFT)">
                    <span>{{ $t('furusato_product.all_draft_product_tab') }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="table-responsive sm-m-b-15">
            <table :class="[entries.length ? '' : 'table-empty-data', 'table dataTable app-table no-footer']">
              <thead>
              <tr style="white-space:nowrap;">
                <th class="text-center cell-fit-content">
                  マスタ番号
                </th>
                <template v-for="column in columns">
                  <th v-if="column.sortable" @click="toggleSortBy(column.name)"
                      :class="[
                    pagination.sortBy === column.name ? (pagination.descending ? 'sorting_desc' : 'sorting_asc') : 'sorting',
                     'text-center',
                     column.fitSize ? 'cell-fit-content' : ''
                     ]">
                    {{ column.label }}
                  </th>
                  <th v-else
                      :class="[ 'text-center', column.fitSize ? 'cell-fit-content' : '']">
                    {{ column.label }}
                  </th>
                </template>
                <th class="text-center cell-fit-content" v-if="actions.editEntry">{{
                  $t('common.edit')
                  }}
                </th>
                <th class="text-center cell-fit-content" v-if="actions.editEntry">{{
                  $t('common.public_or_non_public')
                  }}
                </th>
                <th class="text-center cell-fit-content" v-if="actions.deleteEntry || actions.deleteEntryCondition">{{
                  $t('common.delete')
                  }}
                </th>
                <th class="text-center cell-fit-content" v-for="action in actions.others">
                  {{ action.label }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="!entries.length">
                <td colspan="100">
                  <span v-if="loading">
                    {{ $t('common.loading') }}
                  </span>
                  <span v-else>
                    {{ $t('common.list empty') }}
                  </span>
                </td>
              </tr>
              <tr v-for="entry in entries" class="bg-row-check">
                <td class="app-table-p v-align-middle text-left">
                  <p class="app-table-p app-cell-tooltip px-3" :data-original-title="entry.master_code">
                  <input type="checkbox" :value="entry.id" v-model="selectedEntries" @click=addOrRemoveProductId(entry.id)>
                  <span class="pl-2">{{entry.master_code}}</span>
                  </p>
                </td>
                <td v-for="column in columns" :class="['v-align-middle', column.class]">
                  <p v-if="column.computedHtml" class="app-table-p app-cell-tooltip">
                    <span v-html="column.computedHtml(entry)"></span>
                  </p>
                  <p v-else-if="column.computeATag"
                     class="app-table-p app-cell-tooltip"
                     :data-original-title="column.computed ? column.computed(entry) : _.get(entry, column.name)"
                  >
                    <span>
                      <a
                        :href="_.get(entry, 'public_url')"
                        target='_blank'
                        style='color: #007bff !important;text-decoration: underline'
                      >{{_.get(entry, column.name)}}</a>
                    </span>
                  </p>
                  <p v-else :data-original-title="column.computed ? column.computed(entry) : _.get(entry, column.name)"
                     class="app-table-p app-cell-tooltip">
                    <template v-if="column.name == 'prefectures' || column.name == 'cities' ">
                      <template v-for="(name, index_1) in _.get(entry, column.name)">
                        <div>{{ index_1 + 1 }}. {{ name.name }}</div>
                      </template>
                    </template>
                    <span v-else-if="column.name == 'price'">
                      {{ formatPrice(Number(_.get(entry, column.name))) }}円
                    </span>
                    <span v-else-if="column.name == 'stocks'">
                      {{ formatPrice(Number(_.get(entry, column.name))) }}
                    </span>
                    <span v-else>
                      {{ column.computed ? column.computed(entry) : _.get(entry, column.name) }}
                    </span>
                  </p>
                </td>
                <td class="v-align-middle" v-if="actions.editEntry">
                  <router-link :to="{'path' : entry.id + '/edit'}">
                    <div class="btn-tool edit"></div>
                  </router-link>
                </td>
                <td class="v-align-middle" v-if="actions.editEntry">
                  <button type="button" :class="['btn btn-complete']"
                          data-dismiss="modal" @click="changeStatusProduct(entry.id, entry.status)">
                    {{ entry.status == $consts.INPUT.TYPE_PUBLIC ? $t('furusato_product.text_to_draft_button') : $t('furusato_product.text_to_public_button')  }}
                  </button>
                </td>
                <td class="v-align-middle" v-if="actions.deleteEntry || actions.deleteEntryCondition">
                  <div
                    v-if="(!actions.deleteEntryCondition || actions.deleteEntryCondition && actions.deleteEntryCondition(entry))
                    && entry.status == $consts.INPUT.TYPE_DRAFT"
                    @click="deleteEntry(entry)" class="btn-tool delete">
                  </div>
                  <div
                    v-if="(!actions.deleteEntryCondition || actions.deleteEntryCondition && actions.deleteEntryCondition(entry))
                    && entry.status == $consts.INPUT.TYPE_PUBLIC"
                    @click="deletePublicEntry(entry, entry.id)" class="btn-tool delete">
                  </div>
                </td>
                <td class="v-align-middle" v-for="action in actions.others">
                  <template v-if="action.showCondition ? action.showCondition(entry) : true">
                    <template v-if="action.click">
                      <span v-html="action.contentHtml(entry)" @click="action.click(entry)"></span>
                    </template>
                    <template v-else-if="action.routerLink">
                      <router-link :to="action.routerLink(entry)">
                        <span v-html="action.contentHtml(entry)"></span>
                      </router-link>
                    </template>
                    <template v-else>
                      <span v-html="action.contentHtml(entry)"></span>
                    </template>
                  </template>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="d-flex justify-content-between">
              <div class="dataTables_paginate app-paginate-container">
                <AppPaginate
                  v-show="parseInt(meta.last_page) > 1"
                  ref="paginate"
                  :page-count="parseInt(meta.last_page)"
                  :page-range="5"
                  :margin-pages="2"
                  :click-handler="clickPagination"
                  prev-text="<"
                  next-text=">"
                  breakViewClass="paginate-break"
                  prev-class="paginate-button-prev"
                  next-class="paginate-button-next"
                  :force-page="parseInt(pagination.currentPage)"
                  :hide-prev-next="true"
                  :page-class="'paginate-button'">
                </AppPaginate>
              </div>
              <div v-show="parseInt(meta.total) > 5"
                   class="d-flex justify-content-start padding-20 form-group dataTable_info_custom">
                <div class="m-r-5 m-t-5 perpage-label"> {{ $t('common.perpage') }}
                </div>
                <div class="form-group">
                  <select class="form-control" @change="changePerPage"
                          v-model="pagination.currentPerPage">
                    <option v-for="perpage in pagination.perPages" :value="perpage">
                      {{ perpage }}
                    </option>
                  </select></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  const EVENT_PAGE_CHANGE = 'EVENT_PAGE_CHANGE';
  const EVENT_SORT_BY = 'EVENT_SORT_BY';
  const EVENT_SEARCH = 'EVENT_SEARCH';
  const EVENT_PER_PAGE_CHANGE = 'EVENT_PER_PAGE_CHANGE';

  export default {
    props: {
      settingColumns: Array,
      actions: Object,
      otherActions: Object,
      search: {
        type: Object,
        default: {
          searchAll: false,
          reset: false
        }
      },
      filters: [],
      settingApis: {},
      tableName: String,
    },
    data() {
      const columns = this.settingColumns.map(val => {
        return {
          sortable: false,
          editable: true,
          label: val.label === undefined ? val.name.charAt(0).toUpperCase() + val.name.slice(1) : val.label,
          ...val
        }
      })
      var cloneFilters = this.filters;
      _.forEach(this.$route.query, (value, columnString) => {
        if (_.includes(columnString, 'filters.')) {
          let splited = columnString.split('.');
          let name = splited[1];
          let type = splited[2];
          _.forEach(cloneFilters, (filter, index) => {
            if (filter.name === name && filter.type === type) {
              if (filter.uiType === 'multi_select' && !_.isArray(value)) {
                value = [value]
              }
              cloneFilters[index] = {...cloneFilters[index], value: value}
            }
          })
        }
      })
      const {page, search, sortBy, sortType, perPage} = this.$route.query;
      return {
        activeClass : 'active show',
        menuActive : 0,
        chosen: "",
        columns: columns,
        entriesRes: {},
        entries: [],
        loading: true,
        pagination: {
          currentPage: page ? page : 1,
          search: search ? search : '',
          sortBy: sortBy ? sortBy : null,
          descending: sortType === 'desc',
          currentPerPage: perPage ? perPage : 10,
          perPages: [5, 10, 25, 50, 100, 250, 500],
        },
        meta: {},
        entrySelectedDelete: {},
        productIds: [],
        arrayIndex: [],
        selectedEntries: [],
      }
    },
    watch: {
      "$route.query": {
        handler: async function (after, before) {
          if (!_.isEqual(after, before)) {
            this.getEntries()
          }
        },
        deep: true,
      },
    },
    computed: {
      hasFiltersUi() {
        var hasFiltersUi = false;
        _.forEach(this.filters, filter => {
          if (filter.uiType) {
            hasFiltersUi = true;
          }
        })
        return hasFiltersUi;
      },
    },
    mounted() {
      this.getEntries()
    },
    methods: {
      addOrRemoveProductId(id) {
        const productId = this.productIds.find(item => item == id);
        if (productId == undefined) {
          this.productIds.push(id)
        } else {
          this.productIds =  this.productIds.filter(function(item) {
            return item !== id
          })
        }
      },
      formatPrice(value) {
        return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      },
      async changePrefecture(id, name)
      {
        if (name == "prefecture_id") {
          if (id != undefined) {
            let params = {
              sortType: "asc",
              sortBy: "code",
            };
            params[`filters[prefecture_id][equal]`] = id;
            const res = await this.$request.get(this.$consts.API.ADMIN.TOUR_CITIES, params);

            if (!res.hasErrors()) {
              this.filters[1].options = res.data.data;
              this.filters[1].options.push({
                id: 0,
                name: this.$t('common.select_all'),
              });
            } else {
              this.$appNotice.error(res.data?.error_msg)
            }
          }
        }
      },
      clickPagination(selectPage) {
        if (selectPage !== this.pagination.currentPage) {
          this.setCurrentPage(selectPage)
          this.updateRouteQuery(EVENT_PAGE_CHANGE)
        }
      },
      changePerPage() {
        this.setCurrentPage(1)
        this.updateRouteQuery([EVENT_PAGE_CHANGE, EVENT_PER_PAGE_CHANGE])
      },
      setCurrentPage(page) {
        this.pagination.currentPage = page;
        if (this.$refs.paginate) {
          this.$refs.paginate.handlePageSelected(page);
        }
      },
      searchHandle() {
        this.setCurrentPage(1)
        this.updateRouteQuery([EVENT_PAGE_CHANGE, EVENT_SEARCH])
        this.$emit('searched')
      },
      async getEntries() {
        await this.setSearchStateByRouteQuery()
        const {currentPage, search, sortBy, descending, currentPerPage} = this.pagination
        let params = {
          page: currentPage,
          perPage: currentPerPage,
          search: search,
          sortBy: sortBy,
          sortType: descending ? 'desc' : 'asc',
        }

        const filtersClone = this.filters
        _.forEach(filtersClone, (filter) => {
          if (filter.value && filter.value.toString().length) {
            params[`filters[${filter.name}][${filter.type}]`] = filter.value
          }
        })
        let endpoint = this.settingApis.list.endpoint;
        let apiParams = this.settingApis.list.params;
        if (!apiParams) {
          apiParams = {}
        }
        const res = await this.$request.get(endpoint, {...params, ...apiParams})

        _.forEach(this.$route.query, (value, columnString) => {
          _.forEach(this.filters, (filter, index_ft) => {
            if (columnString.indexOf(filter.name) > 0) {
              this.filters[index_ft].value = value;
            }
          });
        });

        if (!res.hasErrors()) {
          this.entriesRes = _.cloneDeep(res);
          this.entries = res.data.data.map((entry, index) => {
            return {
              ...entry, no: (index + 1 + (this.pagination.currentPage - 1) * this.pagination.currentPerPage)
            }
          })
          this.meta = res.data.meta
          if (!this.meta.from) {
            this.meta.from = 0;
          }
          if (!this.meta.to) {
            this.meta.to = 0;
          }
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
        if (this.pagination.currentPage > 1 && this.entries.length === 0) {
          this.setCurrentPage(1)
          this.updateRouteQuery(EVENT_PAGE_CHANGE)
        }
        this.$nextTick(() => {
          $.each($('.app-cell-tooltip'), (index, el) => {
            if (el.offsetWidth < el.scrollWidth) {
              $(el).tooltip({boundary: 'window'})
            }
          })
        });
        this.loading = false;
      },
      async setSearchStateByRouteQuery() {
        const {page, search, sortBy, sortType, perPage} = this.$route.query;
        this.pagination = {
          currentPage: page ? page : 1,
          search: search ? search : '',
          sortBy: sortBy ? sortBy : null,
          descending: sortType === 'desc',
          currentPerPage: perPage ? perPage : 10,
          perPages: [5, 10, 25, 50, 100, 250, 500],
        }
        var newFilters = _.cloneDeep(this.filters)
        _.forEach(newFilters, (filter, key) => {
          newFilters[key] = {...filter, value: null}
        })
        _.forEach(this.$route.query, (value, columnString) => {
          if (_.includes(columnString, 'filters.')) {
            let splited = columnString.split('.');
            let name = splited[1];
            let type = splited[2];
            _.forEach(newFilters, (filter, key) => {
              if (filter.name === name && filter.type === type) {
                if (filter.uiType === 'multi_select' && !_.isArray(value)) {
                  value = [value]
                }
                newFilters[key] = {...newFilters[key], value: value}
              }
            })
          }
        })

        this.menuActive = newFilters.status_equal.value
        this.activeClass = newFilters.status_equal.value ? "" : "active show"
        await this.$emit('update:filters', newFilters)
      },
      async resetAllSearch() {
        this.chosen = "";
        if (Object.keys(this.$route.query).length === 0) {
          this.pagination.search = ''
          this.getEntries()
        } else {
          this.$router.push({name: this.$route.name, query: {}})
        }
        this.$emit('resetAllSearch')
      },
      toggleSortBy(columnName) {
        if (this.pagination.sortBy !== columnName) {
          this.pagination.sortBy = columnName
          this.pagination.descending = false;
        } else {
          if (!this.pagination.descending) {
            this.pagination.sortBy = columnName
            this.pagination.descending = true;
          } else {
            this.pagination.sortBy = null
          }
        }
        this.updateRouteQuery(EVENT_SORT_BY);
      },
      updateRouteQuery(events) {
        if (!_.isArray(events)) {
          events = [events]
        }
        var queryObj = _.clone(this.$route.query);
        var {currentPage, search, sortBy, descending, currentPerPage} = this.pagination
        _.forEach(events, event => {
          if (event === EVENT_PAGE_CHANGE) {
            if (currentPage > 1) {
              queryObj['page'] = currentPage
            } else {
              delete queryObj['page']
            }
          }
          if (event === EVENT_PER_PAGE_CHANGE) {
            if (parseInt(currentPerPage) !== 10) {
              queryObj['perPage'] = currentPerPage;
            } else {
              delete queryObj['perPage']
            }
          }
          if (event === EVENT_SORT_BY) {
            if (sortBy && sortBy.length) {
              queryObj['sortBy'] = sortBy;
            } else {
              delete queryObj['sortBy'];
            }
            if (sortBy && sortBy.length) {
              queryObj['sortType'] = descending ? 'desc' : 'asc';
            } else {
              delete queryObj['sortType'];
            }
          }
          if (event === EVENT_SEARCH) {
            if (search && search.length) {
              queryObj['search'] = search;
            } else {
              delete queryObj['search'];
            }
            const filters = this.filters;
            _.forEach(filters, (filter) => {
              if (filter.value && filter.value.toString().length) {
                queryObj['filters.' + filter.name + '.' + filter.type] = filter.value
              } else {
                delete queryObj['filters.' + filter.name + '.' + filter.type];
              }
            })
          }
        })

        if (!_.isEqual(queryObj, this.$route.query)) {
          this.$router.push({query: queryObj})
        } else {
          this.getEntries()
        }
      },

      /* DELETE entry start */
      async deleteEntrySubmit() {
        await this.$request.delete(this.$consts.API.ADMIN.FURUSATO_PRODUCT_DELETE_ALL, {
          "productIds": [this.entrySelectedDelete.id], "type": this.$consts.INPUT.TYPE_DELETE_SINGLE}).then(res => {
          if (!res.hasErrors()) {
            this.$appNotice.success(this.$t('common.msg delete product all success'))
            this.$refs.modal_confirm_delete_all.hide();
            this.entries = _.cloneDeep(res.data.data);
            this.getEntries()
            this.selectedEntries = [];
            this.productIds = []
            this.$refs.modal_delete_entry.hide();
            this.$refs.modal_delete_public_entry.hide();
          }else {
            this.$appNotice.error( res.data.error_msg !== undefined ? res.data.error_msg : this.$t('common.error'))
          }
        })
      },
      deleteEntry(entry) {
        this.entrySelectedDelete = entry;
        this.$refs.modal_delete_entry.show();
      },
      deletePublicEntry(entry) {
        this.entrySelectedDelete = entry;
        this.$refs.modal_delete_public_entry.show();
      },
      confirmPublishAll() {
        this.$refs.modal_confirm_publish_all.show();
      },
      async publishAllProduct() {
        await this.$request.post(this.$consts.API.ADMIN.FURUSATO_PRODUCT_PUBLISH_ALL, {
          "productIds": this.productIds, "status": this.$consts.INPUT.TYPE_PUBLIC, "type": this.$consts.INPUT.TYPE_PUBLISH_ALL}).then(res => {
          if (!res.hasErrors() && this.productIds.length != 0) {
            this.$appNotice.success(this.$t('common.msg public product all success'))
            this.$refs.modal_confirm_publish_all.hide()
            this.getEntries()
            this.selectedEntries = [];
            this.productIds = []
          } else if (this.productIds.length == 0) {
            this.$appNotice.error(this.$t('furusato_product.message_error_no_product_select'))
          } else {
            this.$appNotice.error( res.data.error_msg !== undefined ? res.data.error_msg : this.$t('common.error'))
          }
        })
      },
      confirmDraftAll() {
        this.$refs.modal_confirm_draft_all.show();
      },
      async draftAllProduct() {
        await this.$request.post(this.$consts.API.ADMIN.FURUSATO_PRODUCT_DRAFT_ALL, {
          "productIds": this.productIds, "status": this.$consts.INPUT.TYPE_DRAFT, "type": this.$consts.INPUT.TYPE_DRAFT_ALL}).then(res => {
          if (!res.hasErrors() && this.productIds.length != 0) {
            this.$appNotice.success(this.$t('common.msg draft product all success'))
            this.$refs.modal_confirm_draft_all.hide();
            this.getEntries()
            this.selectedEntries = [];
            this.productIds = []
          } else if (this.productIds.length == 0) {
            this.$appNotice.error(this.$t('furusato_product.message_error_no_product_select'))
          } else {
            this.$appNotice.error( res.data.error_msg !== undefined ? res.data.error_msg : this.$t('common.error'))
          }
        })
      },
      changeStatusProduct(id, status) {
        this.$request.post(this.$consts.API.ADMIN.CHANGE_STATUS_FURUSATO_PRODUCT, {
          "productIds": [id], "status": status}).then(res => {
          if (!res.hasErrors()) {
            this.$appNotice.success(this.$t('common.msg update ok'))
            this.getEntries()
          } else {
            this.$appNotice.error( res.data.error_msg !== undefined ? res.data.error_msg : this.$t('common.error'))
          }
        })
      },
      confirmDeleteAll() {
        this.$refs.modal_confirm_delete_all.show();
      },
      async deleteAllProduct() {
        await this.$request.delete(this.$consts.API.ADMIN.FURUSATO_PRODUCT_DELETE_ALL_CHECK, {
          "productIds": this.productIds, "type": this.$consts.INPUT.TYPE_DELETE_ALL}).then(res => {
          if (!res.hasErrors() && this.productIds.length != 0) {
            this.$appNotice.success(this.$t('common.msg delete product all success'))
            this.$refs.modal_confirm_delete_all.hide();
            this.entries = _.cloneDeep(res.data.data);
            this.getEntries()
            this.selectedEntries = [];
            this.productIds = []
          } else if (this.productIds.length == 0) {
            this.$refs.modal_confirm_delete_all.hide();
            this.getEntries()
            this.$appNotice.error(this.$t('furusato_product.message_error_no_product_select'))
          } else {
            this.$appNotice.error( res.data.error_msg !== undefined ? res.data.error_msg : this.$t('common.error'))
            this.$refs.modal_confirm_delete_all.hide();
            this.getEntries()
          }
        })
      },
      getListProduct(status) {
        const filters = this.filters
        var param_value_code = []
        var param_value_city = []
        var param_value_office = []
        var param_value_name = []
        var param_value_type = []
        var param_value_price = []
        var param_value_status = []
        var param_value_create = []
        if (filters.master_code_multi_like.value) {
          var query_code = "filters[master_code][multi_like]"
          var arrayFilterMasterCode = filters.master_code_multi_like.value
          if (Array.isArray(arrayFilterMasterCode) && arrayFilterMasterCode.length > 1) {
            _.forEach(filters.master_code_multi_like.value, (value) => {
              param_value_code.push(value)
            })
          } else {
            param_value_code = arrayFilterMasterCode
          }
        }
        if (filters.full_city_id_equal.value) {
          var query_city = "filters[full_city_id][equal]"
          var arrayFilterCity = filters.full_city_id_equal.value
          if (Array.isArray(arrayFilterCity) && arrayFilterCity.length > 1) {
            _.forEach(filters.full_city_id_equal.value, (value) => {
              param_value_city.push(value)
            })
          } else {
            param_value_city = arrayFilterCity
          }
        }
        if (filters.office_id_equal.value) {
          var query_office = "filters[office_id][equal]"
          var arrayFilterOffice = filters.office_id_equal.value
          if (Array.isArray(arrayFilterOffice) && arrayFilterOffice.length > 1) {
            _.forEach(filters.office_id_equal.value, (value) => {
              param_value_office.push(value)
            })
          } else {
            param_value_office = arrayFilterOffice
          }
        }
        if (filters.name_multi_like.value) {
          var query_name = "filters[name][multi_like]"
          var arrayFilterName = filters.name_multi_like.value
          if (Array.isArray(arrayFilterName) && arrayFilterName.length > 1) {
            _.forEach(filters.name_multi_like.value, (value) => {
              param_value_name.push(value)
            })
          } else {
            param_value_name = arrayFilterName
          }
        }
        if (filters.type_equal.value) {
          var query_type = "filters[type][equal]"
          var arrayFilterType = filters.type_equal.value
          if (Array.isArray(arrayFilterType) && arrayFilterType.length > 1) {
            _.forEach(filters.type_equal.value, (value) => {
              param_value_type.push(value)
            })
          } else {
            param_value_type = arrayFilterType
          }
        }
        if (filters.price_multi_like.value) {
          var query_price = "filters[price][multi_like]"
          var arrayFilterPrice = filters.price_multi_like.value
          if (Array.isArray(arrayFilterPrice) && arrayFilterPrice.length > 1) {
            _.forEach(filters.price_multi_like.value, (value) => {
              param_value_price.push(value)
            })
          } else {
            param_value_price = arrayFilterPrice
          }
        }
        filters.status_equal.value = status ? status : 0
        this.menuActive = filters.status_equal.value
        this.activeClass = ""
        if (filters.status_equal.value) {
          var query_status = "filters[status][equal]"
          param_value_status = filters.status_equal.value
        }
        if (filters.price_multi_like.value) {
          var query_create = "filters[created_at][equal]"
          param_value_create = filters.created_at_equal.value
        }
        this.$request.get(this.$consts.API.ADMIN.FURUSATO_PRODUCT, {
          "status": status,
          [query_code]: param_value_code,
          [query_city]: param_value_city,
          [query_office]: param_value_office,
          [query_name]: param_value_name,
          [query_type]: param_value_type,
          [query_price]: param_value_price,
          [query_status]: param_value_status,
          [query_create]: param_value_create,
        }).then(res => {
          if (!res.hasErrors()) {
            this.entries = _.cloneDeep(res.data.data);
          }
        })
      }
    }
  };
</script>

<style type="text/css">
  .default-input {
    height: 50px;
  }
  .furusato-nav {
    border-bottom: none;
  }
  .btn-draft-center {
    margin: 0 auto;
  }
  .modal-product {
    text-align: center;
  }
  .span-text {
    font-size: 20px;
    font-weight: bold;
    color: #015CAC;
    text-align: center;
    display: block;
    margin-top: 20px;
    height: 50px;
    line-height: 50px;
  }
</style>
