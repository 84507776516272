<template>
  <div>
    <FurusatoProductTable
      ref="table"
      :table-name="tableName"
      :setting-columns="columns"
      :actions="actions"
      :filters.sync="filters"
      :search="search"
      :setting-apis="apis"
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
      @deletedEntry="onDeletedEntry"
    ></FurusatoProductTable>
  </div>
</template>
<script>
  import FurusatoProductTable from "@components/_common/List/FurusatoProductTable";

  export default {
    data() {
      return {
        tableName: this.$t('users.user list'),
        apis: {
          list: {
            endpoint: this.$consts.API.ADMIN.FURUSATO_PRODUCT,
          },
          delete: {
            endpoint: this.$consts.API.ADMIN.FURUSATO_PRODUCT_DELETE,
          }
        },
        actions: {
          name: "furusato_product",
          editEntry: true,
          deleteEntry: true,
          routerLink: "furusato-product/",
        },
        search: {
          searchAll: false,
          reset: true,
        },
        filters: {
          master_code_multi_like: {
            name: 'master_code',
            type: 'multi_like',
            label: this.$t('furusato_product.master_code'),
            uiType: 'multi_select',
            ui_options: {
              closeOnSelect: true,
            },
            options: [],
            boundaryClass: 'col-md-3 col-sm-12'
          },
          full_city_id_equal: {
            name: 'full_city_id',
            type: 'multi_like',
            label: this.$t('furusato_product.furusato_city_id'),
            uiType: 'multi_select',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: 'col-md-3 col-sm-12',
            options: []
          },
          office_id_equal: {
            name: 'office_id',
            type: 'multi_like',
            label: this.$t('furusato_product.furusato_office_id'),
            uiType: 'multi_select',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: 'col-md-3 col-sm-12',
            options: []
          },
          name_multi_like: {
            name: 'name',
            type: 'multi_like',
            label: this.$t('furusato_product.name'),
            uiType: 'multi_select',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: 'col-md-3 col-sm-12',
            options: []
          },
          type_equal: {
            name: 'type',
            type: 'equal',
            label: this.$t('furusato_product.category_list'),
            uiType: 'multi_select',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: 'col-md-3 col-sm-12',
            options: [
              {
                id: "1",
                name: "宿泊"
              },
              {
                id: "2",
                name: " 飲食",
              },
              {
                id: "3",
                name: "体験",
              },
            ]
          },
          price_multi_like: {
            name: 'price',
            type: 'multi_like',
            label: this.$t('furusato_product.price'),
            uiType: 'input',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: 'col-md-2 col-sm-12',
            options: []
          },
          status_equal: {
            name: 'status',
            type: 'equal',
            label: this.$t('common.status'),
            uiType: 'select',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: 'col-md-2 col-sm-12',
            options: [
              {
                id: "0",
                name: "全ての商品"
              },
              {
                id: "1",
                name: "WEBカタログに公開中",
              },
              {
                id: "2",
                name: "下書き",
              },
            ]
          },
          created_at_equal: {
            name: 'created_at',
            type: 'equal',
            label: this.$t('furusato_product.created_at'),
            uiType: 'datepicker',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: 'col-md-2 col-sm-12',
            options: []
          },
          input_hidden_2: {
            name: 'hidden',
            type: 'equal',
            label: " ",
            uiType: 'input_hidden',
            boundaryClass: 'space-date',
          },
          created_end_equal: {
            name: 'created_end',
            type: 'equal',
            label: " ",
            uiType: 'datepicker',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: 'col-md-2 col-sm-12',
            options: []
          },
        },
        entrySelectedDelete: {},
        entryToSave: {active: 1},
        entryToEdit: {},
        columns: [
          {name: 'full_city_id', label: this.$t('furusato_product.furusato_city_id'),
            computedHtml: furusato_product => {
              return furusato_product.cities.name;
            }},
          {name: 'office_id', label: this.$t('furusato_product.furusato_office_id'),
            computedHtml: furusato_product => {
              return furusato_product.offices ? furusato_product.offices.office_name : "";
            }},
          {name: 'name', label: this.$t('furusato_product.name')},
          {name: 'category', label: this.$t('furusato_product.category'),
            computedHtml: furusato_product => {
              let categorySleep = furusato_product.type_sleep === 1 ? this.$t('furusato_product.category_sleep') : ""
              let categoryFood = furusato_product.type_food === 1 ? this.$t('furusato_product.category_food') : ""
              let categoryExperience = furusato_product.type_experience === 1 ? this.$t('furusato_product.category_experience') : ""
              let categoryLabel = ''
              if (categorySleep) {
                categoryLabel += '/' + categorySleep;
              }
              if (categoryFood) {
                categoryLabel += '/' + categoryFood;
              }
              if (categoryExperience) {
                categoryLabel += '/' + categoryExperience;
              }
              return categoryLabel.replace(/^\/|\/$/g, '')
              }
            },
          {name: 'price', label: this.$t('furusato_product.price'), class: 'min-w-100'},
          {name: 'stocks', label: this.$t('furusato_product.stocks'), class: 'min-w-100'},
          {name: 'status', label: this.$t('common.status'), class: 'min-w-100',
            computedHtml: furusato_product => {
              let status = '';
              let furusatoProductStatus = furusato_product.status;
              if (furusatoProductStatus == this.$consts.INPUT.TYPE_PUBLIC) {
                status = this.$t('furusato_product.public');
              } else if (furusatoProductStatus == this.$consts.INPUT.TYPE_DRAFT) {
                status = this.$t('furusato_product.draft');
              }
              return status;
            }},
          {name: 'reception_date', label: this.$t('furusato_product.reception_date'), class: 'min-w-100',
            computedHtml: furusato_product => {
              return furusato_product.publish_date + '~ </br>' + furusato_product.expire_date
            }},
          {name: 'created_at', label: this.$t('furusato_product.created_at'), class: 'min-w-100',
            computedHtml: furusato_product => {
              return moment(furusato_product.created_at).format('YYYY年MM月DD日 HH:mm');
            }},
        ]
      }
    },
    components: {
      FurusatoProductTable
    },
    mounted() {
      this.$request.get(this.$consts.API.ADMIN.UI_HELPER_FURUSATO_PRODUCT).then(res => {
        if (!res.hasErrors()) {
          this.filters.master_code_multi_like.options = _.cloneDeep(res.data.master_code);
          this.filters.full_city_id_equal.options = _.cloneDeep(res.data.full_city);
          this.filters.office_id_equal.options = _.cloneDeep(res.data.offices);
          this.filters.name_multi_like.options = _.cloneDeep(res.data.name);
          this.filters.price_multi_like.options = _.cloneDeep(res.data.price);
        }
      })
    },
    methods: {
      getFurusatoProduct() {
        this.$request.get(this.$consts.API.ADMIN.FURUSATO_PRODUCT).then(res => {
          if(!res.hasErrors()) {
            this.filters = _.cloneDeep(this.filters)
          }
        })
      },
      onResetAllSearch() {
        this.getFurusatoProduct();
      },
      onSearch() {
        this.getFurusatoProduct();
      },
      onDeletedEntry() {
        this.getFurusatoProduct();
      },
    }
  }
</script>
